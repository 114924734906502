<template>
  <v-container xs12 sm6 offset-sm3>

    <v-row justify="center">
      <div class="hoofd" @click="begin">
        <transition
            enter-active-class="animate__animated animate__flipInY"
            appear

        >
          <v-img
              contain
              class="logoo"
              src="img/logo-kop.png"
              :width="imageHeight"
              alt="logo"
              style="cursor: pointer"
          />
        </transition>
      </div>
    </v-row>
    <v-row justify="center">
      <div class="tekst">
        <transition
            enter-active-class="animate__animated animate__slideInDown"
            appear

        >
          <v-img
              contain
              class="logoo2"
              src="img/logotekst.png"
              :width="imageHeight2"
              alt="logo"/>
        </transition>
      </div>
    </v-row>
    <v-row justify="center">
      <div class="verder secondary--text">
        <transition
            enter-active-class="animate__animated animate__fadeInLeft"
            appear

        >
          <p class="logoo3">Klik op het logo om verder te gaan</p>
        </transition>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Start",


  data() {
    return {
      animated: false

    }


  },

  methods: {
    begin(){
      this.$router.push({name: 'Module1'})


    },



  },

  computed: {
    // eslint-disable-next-line
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '200px'
        case 'sm':
          return '350px'
        case 'md':
          return '350px'
        case 'lg':
          return '350px'
        case 'xl':
          return '600px'
      }
    },
    // eslint-disable-next-line
    imageHeight2() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '200px'
        case 'sm':
          return '350px'
        case 'md':
          return '350px'
        case 'lg':
          return '350px'
        case 'xl':
          return '600px'
      }
    },

  },
}


</script>

<style scoped>

.hoofd {
  padding-top: 10vh;
}

.logoo {
  animation-duration: 3s;
  padding-top: 10vh;


}

.logoo2 {
  animation-duration: 1s;
  animation-delay: 3s;
}
.logoo3 {
  animation-duration: 1s;
  animation-delay: 3s;
  padding-top: 5vh;


}

.verder {
  animation-delay: 3s;

}


v-image {
  margin-left: 20px;

}

</style>
