<template>
  <Start></Start>
</template>

<script>

import Start from "./Start"

export default {
  components: {
    Start,



  }
}
</script>
