import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Module1 from "../views/Module1";

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },

    {
        path: '/quickscan',
        name: 'Module1',
        component: Module1,

    },
]

const router = new VueRouter({
    mode:'history',
    routes
})

export default router
